import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { MaintenanceControl } from "../../models/maintenanceControl.model";

@Injectable({
    providedIn: "root",
})
export class MaintenanceControlsDbDao extends DbDaoBase<MaintenanceControl> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + MaintenanceControl.TABLENAME + "_id"
                    + " ON " + MaintenanceControl.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + MaintenanceControl.TABLENAME + "_equipmentId"
                + " ON " + MaintenanceControl.TABLENAME + "(equipmentId);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + MaintenanceControl.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "wording TEXT, "
                    + "equipmentId TEXT, "
                    + "plannedMinDate TEXT, "
                    + "plannedDate TEXT, "
                    + "plannedMaxDate TEXT, "
                    + "_order NUMERIC"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + MaintenanceControl.TABLENAME + " WHERE id ='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + MaintenanceControl.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    get(id: string, hydrate: boolean = false): Promise<MaintenanceControl> {
        let selectQuery = "SELECT * FROM " + MaintenanceControl.TABLENAME + " WHERE id = '" + id + "';";
        if (isNullOrEmpty(id)) {
            return Promise.resolve(null);
        }

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                return this.rowToModel(data.rows[0]);
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return MaintenanceControl.TABLENAME;
    }

    protected rowToModel(row: any): MaintenanceControl {
        let maintenanceControl = new MaintenanceControl();

        maintenanceControl.id = row.id;
        maintenanceControl.wording = row.wording;
        maintenanceControl.equipmentId = row.equipmentId;
        maintenanceControl.plannedMinDate = row.plannedMinDate;
        maintenanceControl.plannedDate = row.plannedDate;
        maintenanceControl.plannedMaxDate = row.plannedMaxDate;
        maintenanceControl.order = row.order;

        return maintenanceControl;
    }

    save(maintenanceControl: MaintenanceControl): Promise<MaintenanceControl> {
        let query = "INSERT OR REPLACE INTO " + MaintenanceControl.TABLENAME
                    + " (id, wording, equipmentId, plannedMinDate, plannedDate, plannedMaxDate, _order) VALUES ("
                    + this.getValue(maintenanceControl.id)
                    + this.getValue(maintenanceControl.wording)
                    + this.getValue(maintenanceControl.equipmentId)
                    + this.getValue(maintenanceControl.plannedMinDate)
                    + this.getValue(maintenanceControl.plannedDate)
                    + this.getValue(maintenanceControl.plannedMaxDate)
                    + this.getValue(maintenanceControl.order, true)
                    + ");";

        return this.sqlProvider.query(query)
            .then(response => {
                return maintenanceControl;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public listForEquipment(equipmentId: string): Promise<MaintenanceControl[]> {
        let selectQuery = "SELECT * FROM " + MaintenanceControl.TABLENAME + " WHERE equipmentId='" + equipmentId + "' ORDER BY _order;";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                let result: MaintenanceControl[] = [];

                for (const row of data.rows) {
                    result.push(this.rowToModel(row));
                }

                return result;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
