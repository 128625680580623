import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { environment } from "../../../../environments/environment";
import { KeyValueDbDao } from "../../../../gyzmo-commons/dao/db/keyValue.db.dao";
import { DateHelper } from "../../../../gyzmo-commons/helpers/date.helper";
import { NetworkHelper } from "../../../../gyzmo-commons/helpers/network.helper";
import { DATE_FORMAT_FOR_PICKER } from "../../../../gyzmo-commons/interfaces/constants";
import { AppSqlProvider } from "../../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../../gyzmo-commons/services/logs/logger.service";
import { DamageValuesDbDao } from "../../../dao/db/inspection/common/damageValues.db.dao";
import { InspectionDbDaoV2 } from "../../../dao/db/inspection/v2/inspection.db.dao";
import { InspectionWsDaoV2 } from "../../../dao/ws/inspection/v2/inspection.ws.dao";
import { DamageValueDto } from "../../../dto/inspection/common/damageValue.dto";
import { InspectionDto } from "../../../dto/inspection/v2/inspection.dto";
import { INSPECTION_KINDS } from "../../../interfaces/INSPECTION_KINDS";
import { ServersConnectionsProvider } from "../../../providers/serversConnections.provider";
import { MaintenanceControlsService } from "../../maintenanceControls.service";
import { InspectionServiceBase } from "../common/inspection.service.base";
import { InspectionFacesServiceV2 } from "./inspectionFaces.service";

@Injectable({
    providedIn: "root",
})
export class InspectionServiceV2 extends InspectionServiceBase {
    private inspectionsMockCache = new Map<string, InspectionDto>();
    private inspectionsCountCorrectionsMock = new Map<string, number[]>();

    constructor(private logger: LoggerService,
                private appSqlProvider: AppSqlProvider,
                keyValueDbDao: KeyValueDbDao,
                inspectionWsDao: InspectionWsDaoV2,
                private networkHelper: NetworkHelper,
                private inspectionDbDao: InspectionDbDaoV2,
                private damageValuesDbDao: DamageValuesDbDao,
                serversConnectionsProvider: ServersConnectionsProvider,
                private maintenanceControlsService: MaintenanceControlsService,
                private inspectionFacesServiceV2: InspectionFacesServiceV2) {
        super(keyValueDbDao, inspectionWsDao, serversConnectionsProvider);
    }

    public isInspectionSame(localInspection: InspectionDto, remoteInspection: InspectionDto) {
        return localInspection.id == remoteInspection.id && localInspection.kind == remoteInspection.kind;
    }

    public save(inspection: InspectionDto) {
        return new Promise<void>(resolve => {

            this.inspectionWsDao.save(this.serversConnectionsProvider.getServerConnection(), inspection)
                .then(() => {
                    void this.inspectionDbDao.delete(inspection.id)
                        .then(() => {
                            if (environment.mocked) {
                                let countCorrection: number[] = [];
                                if (this.inspectionsCountCorrectionsMock.has(inspection.visitDate.toFormat(DATE_FORMAT_FOR_PICKER))) {
                                    countCorrection = this.inspectionsCountCorrectionsMock.get(inspection.visitDate.toFormat(DATE_FORMAT_FOR_PICKER));
                                } else {
                                    countCorrection = [0, 0, 0];
                                }

                                switch (inspection.kind) {
                                    case INSPECTION_KINDS.DEPARTURE:
                                        countCorrection[0] -= 1;
                                        countCorrection[1] += 1;

                                        inspection.kind = INSPECTION_KINDS.BACK;
                                        this.resetMockedInspectionStates(inspection);
                                        break;
                                    case INSPECTION_KINDS.BACK:
                                        countCorrection[1] -= 1;

                                        inspection.kind = INSPECTION_KINDS.CANCELED;
                                        this.resetMockedInspectionStates(inspection);
                                        break;
                                    case INSPECTION_KINDS.INTERMEDIATE:
                                        countCorrection[2] -= 1;

                                        inspection.kind = INSPECTION_KINDS.CANCELED;
                                        this.resetMockedInspectionStates(inspection);
                                        break;
                                }

                                this.inspectionsMockCache.set(inspection.id, inspection);
                                this.inspectionsCountCorrectionsMock.set(inspection.visitDate.toFormat(DATE_FORMAT_FOR_PICKER), countCorrection);
                            }

                            resolve();
                        });
                })
                .catch(reason => {
                    this.logger.error(this.constructor.name, reason);
                    void this.inspectionDbDao.delete(inspection.id)
                        .then(() => {
                            resolve();
                        });
                });
        });
    }

    public async getCountBetweenDatesWithOfflineFallback(startDate: DateTime, endDate: DateTime): Promise<number[][]> {
        let result;

        try {
            if (!this.networkHelper.isConnected()) {
                throw new Error("offline");
            }

            result = await this.getCountBetweenDatesOnline(startDate, endDate);
        } catch {
            result = await this.getCountBetweenDatesOffline(startDate, endDate);
        }

        if (environment.mocked) {
            let activeDay = startDate;
            let activeIndex = 0;

            while (activeDay <= endDate) {
                if (this.inspectionsCountCorrectionsMock.has(activeDay.toFormat(DATE_FORMAT_FOR_PICKER))) {
                    let countCorrection = this.inspectionsCountCorrectionsMock.get(activeDay.toFormat(DATE_FORMAT_FOR_PICKER));
                    result[activeIndex][0] += countCorrection[0];
                    result[activeIndex][1] += countCorrection[1];
                    result[activeIndex][2] += countCorrection[2];
                }
                activeDay = activeDay.plus({ days: 1 });
                activeIndex++;
            }
        }

        return result;
    }

    public clearMockCache() {
        this.inspectionsMockCache = new Map<string, InspectionDto>();
        this.inspectionsCountCorrectionsMock = new Map<string, number[]>();
    }

    public getByIdOffline(id: string): Promise<InspectionDto> {
        return this.inspectionDbDao.get(id, true)
            .then(async inspection => {
                if (!inspection) {
                    return null;
                }
                let inspectionDto = InspectionDto.fromModel(inspection);
                inspectionDto = this.getInspectionFromMockCache(inspectionDto);

                await this.fillChecklistsPossibleValues(inspectionDto);

                for (let face of inspectionDto.faces) {
                    face.picture = await this.inspectionFacesServiceV2.getFacePicture(face.id);
                }

                return inspectionDto;
            });
    }

    public async getByIdOnline(id: string, loadFacesAndChecklistPossibleValues = false): Promise<InspectionDto> {
        let inspectionDto = await this.inspectionWsDao.getById(this.serversConnectionsProvider.getServerConnection(), id);
        await this.inspectionFacesServiceV2.synchronizeForModel(inspectionDto.inspectionModel.id);
        await this.maintenanceControlsService.synchronizeForEquipment(inspectionDto.equipment.id, inspectionDto.id);

        inspectionDto = this.getInspectionFromMockCache(inspectionDto);

        if (loadFacesAndChecklistPossibleValues) {
            await this.fillChecklistsPossibleValues(inspectionDto);

            for (let face of inspectionDto.faces) {
                face.picture = await this.inspectionFacesServiceV2.getFacePicture(face.id);
            }
        }

        return inspectionDto;
    }

    public async getInspectionsByDateWithOfflineFallback(date: DateTime, maxCountsByType = [0, 0, 0]): Promise<{ date: DateTime, inspections: InspectionDto[] }> {
        let inspections = [];

        try {
            if (!this.networkHelper.isConnected()) {
                throw new Error("offline");
            }

            inspections = await this.synchronizeInspections(date);
        } catch {
            inspections = await this.getOfflineInspectionList(date, date, true, true, false);
        }

        return { date, inspections };
    }

    public async getListWithOfflineFallback(startDate: DateTime, endDate: DateTime): Promise<InspectionDto[]> {
        let result;

        try {
            if (!this.networkHelper.isConnected()) {
                throw new Error("offline");
            }

            result = await this.getOnlineInspectionList(startDate, endDate);
        } catch {
            result = await this.getOfflineInspectionList(startDate, endDate, false, true, false);
        }

        return result;
    }

    public async delete(id: string) {
        await this.inspectionWsDao.delete(this.serversConnectionsProvider.getPrimaryServerConnection(), id);
        await this.inspectionDbDao.delete(id);
    }

    public async synchronizeInspection(id: string): Promise<InspectionDto> {
        let offlineInspection = await this.getByIdOffline(id);
        if (offlineInspection) {
            return offlineInspection;
        }

        this.appSqlProvider.enableBulkWriting();
        let onlineInspection = await this.getByIdOnline(id, true);
        await this.inspectionDbDao.save(onlineInspection.toModel());
        await this.appSqlProvider.commitBulk();

        return await this.getByIdOffline(id);
    }

    private async fillChecklistsPossibleValues(inspection: InspectionDto) {
        for (const checklist of inspection.checklists) {
            for (const element of checklist.elements) {
                element.possibleValues = await this.damageValuesDbDao.getByCategoryId(element.checklistModelId)
                    .then(damageValues => {
                        let result = [];

                        damageValues.forEach(damageValue => {
                            result.push(DamageValueDto.fromModel(damageValue));
                        });

                        return result;
                    });
            }
        }
    }

    private async getCountBetweenDatesOffline(startDate: DateTime, endDate: DateTime) {
        let allInspections = await this.getOfflineInspectionList(startDate, endDate, false, false, false);

        let result: number[][] = [];
        let activeDay = startDate;

        while (activeDay <= endDate) {
            let counts: number[] = [
                allInspections.filter((value) => {
                    return value.isOnDay(activeDay) && (value.kind == INSPECTION_KINDS.DEPARTURE);
                }).length,

                allInspections.filter((value) => {
                    return value.isOnDay(activeDay) && (value.kind == INSPECTION_KINDS.BACK);
                }).length,

                allInspections.filter((value) => {
                    return value.isOnDay(activeDay) && (value.kind == INSPECTION_KINDS.INTERMEDIATE);
                }).length,
            ];

            result.push(counts);

            activeDay = activeDay.plus({ days: 1 });
        }

        return result;
    }

    private async getCountBetweenDatesOnline(startDate: DateTime, endDate: DateTime): Promise<number[][]> {
        let result: number[][] = [];

        let serverConnection = this.serversConnectionsProvider.getPrimaryServerConnection();
        let countBetweenDates = await this.inspectionWsDao.getCountBetweenDates(
            serverConnection,
            startDate,
            endDate,
        );

        let activeDay = startDate;

        while (activeDay <= endDate) {
            let foundElement = countBetweenDates.find((element) => {
                return DateHelper.isSameDay(element.date, activeDay);
            });

            if (foundElement) {
                let counts: number[] = [
                    foundElement.departureCount,
                    foundElement.returnCount,
                    foundElement.intermediateCount,
                ];

                result.push(counts);
            }

            activeDay = activeDay.plus({ days: 1 });
        }

        return result;
    }

    private getInspectionFromMockCache(inspection: InspectionDto): InspectionDto {
        if (!environment.mocked) {
            return inspection;
        }

        if (this.inspectionsMockCache.has(inspection.id)) {
            return this.inspectionsMockCache.get(inspection.id);
        }

        return inspection;
    }

    private getOfflineInspectionList(startDate: DateTime,
                                     endDate: DateTime,
                                     hydrateEquipment: boolean,
                                     hydrateMovement: boolean,
                                     hydrateObjects: boolean): Promise<InspectionDto[]> {
        startDate = startDate.startOf("day");
        endDate = endDate.endOf("day");

        return this.inspectionDbDao.getList(startDate, endDate, hydrateEquipment, hydrateMovement, hydrateObjects)
            .then(inspections => {
                let result = [];

                inspections.forEach(inspection => {
                    let inspectionDto = InspectionDto.fromModel(inspection);
                    inspectionDto = this.getInspectionFromMockCache(inspectionDto);
                    result.push(inspectionDto);
                });

                return result;
            });
    }

    private async getOnlineInspectionList(startDate: DateTime, endDate: DateTime): Promise<InspectionDto[]> {
        startDate = startDate.startOf("day");
        endDate = endDate.endOf("day");

        let serverConnection = this.serversConnectionsProvider.getPrimaryServerConnection();

        let onlineResults = await this.inspectionWsDao.getList(serverConnection, startDate, endDate);
        for (let i = 0; i < onlineResults.length; i++) {
            let inspectionDto = onlineResults[i];
            onlineResults[i] = this.getInspectionFromMockCache(inspectionDto);
        }

        return onlineResults;
    }

    private resetMockedInspectionStates(inspection: InspectionDto) {
        inspection.attachments = [];
        inspection.faces.forEach(face => {
            face.zones.forEach(zone => {
                zone.damages.forEach(damage => {
                    damage.id = "xxxx";
                    damage.isOld = true;
                });
            });
        });
        inspection.checklists.forEach(checklist => {
            checklist.elements.forEach(element => {
                element.previousValue = element.savedValue;
            });
        });
    }

    private synchronizeInspections(date: DateTime): Promise<InspectionDto[]> {
        let t0 = performance.now();
        return new Promise<InspectionDto[]>((resolve, reject) => {
            let promises = [];
            let remoteInspections: InspectionDto[] = [];
            let localInspections: InspectionDto[] = [];

            const startDate = date.startOf("day");
            const endDate = date.endOf("day");

            this.appSqlProvider.enableBulkWriting();

            promises.push(this.getOnlineInspectionList(startDate, endDate)
                .then(value => {
                    remoteInspections = value;
                }));
            promises.push(this.getOfflineInspectionList(startDate, endDate, false, false, false)
                .then(value => {
                    localInspections = value;
                }));

            Promise.all(promises)
                .then(() => {
                    let synchronisationPromises = [];
                    let getDetailsPromises = [];

                    remoteInspections.forEach(remoteInspection => {
                        let localInspection = localInspections.find(localInspection => {
                            return this.isInspectionSame(localInspection, remoteInspection);
                        });

                        if (!localInspection) {
                            getDetailsPromises.push(this.getByIdOnline(remoteInspection.id, false)
                                .then(value => {
                                    let inspection = value.toModel();
                                    return this.inspectionDbDao.save(inspection);
                                })
                                .catch(reason => {
                                    this.logger.error(this.constructor.name, reason);
                                }));
                            getDetailsPromises.push(this.maintenanceControlsService.synchronizeForEquipment(remoteInspection.equipment.id, remoteInspection.id));
                        }
                    });

                    localInspections.forEach(localInspection => {
                        let remoteInspection = remoteInspections.find(remoteInspection => {
                            return this.isInspectionSame(localInspection, remoteInspection);
                        });

                        if (!remoteInspection) {
                            synchronisationPromises.push(this.inspectionDbDao.delete(localInspection.id));
                        }
                    });

                    void Promise.all(synchronisationPromises)
                        .then(() => {
                            void Promise.all(getDetailsPromises)
                                .then(async () => {
                                    await this.appSqlProvider.commitBulk();

                                    let synchronizedInspections = await this.getOfflineInspectionList(date, date, true, true, false);

                                    let t1 = performance.now();
                                    this.logger.info(this.constructor.name, "Call to synchronizeInspections took " + (t1 - t0) + " milliseconds.");
                                    resolve(synchronizedInspections);
                                });
                        });

                })
                .catch(reason => {
                        reject(reason);
                    },
                );
        });
    }
}
